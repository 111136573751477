html {
  background: #f9f9f9;
  --menu_open_color: #CCCCCC;
  --menu_open_bg_color: #356B89;
  --menu_open_hover_color: #FFFFFF;
  --menu_section_bg_color: #999999;
  --menu_section_color: #FFFFFF;
  --menu_selected_bg_color: #3C7C9F;
  --menu_selected_color: #FFFFFF;
  --menu_bg_color: #E9E9E9;
  --menu_color: #4E4E4E;
  --menu_base_bg_color: #E9E9E9;
}
