.custom-branding .rc-color-picker-trigger{
  width: 32px !important;
  height: 32px !important;
  border: 1px solid #d9d9d9;
}

.custom-branding .ant-upload.ant-upload-select-picture-card{
  width: 100% !important;
}

.custom-branding .ant-upload-list-picture-card .ant-upload-list-item{
  width: 100% !important;
}

.custom-branding .ant-form-item label > .anticon{
  vertical-align: -0.125em !important;
}

.custom-branding .custom_favicon .ant-upload-list-item-thumbnail{
  width: 32px !important;
  height: 32px !important;
}

.custom-branding .custom_logo .ant-upload-list-item-thumbnail, 
.custom-branding .custom_background .ant-upload-list-item-thumbnail{
  width: auto !important;
  height: auto !important;
}

.custom-branding .ant-upload-list-item-info > span, 
.custom-branding .ant-modal-body{
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.custom-branding span.label_colors{
  padding-bottom: 4px;
  font-weight: bold;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.85);
  display: block;
  width: 87%;
}

.custom-branding div.page_title{
  height: 30px;
  font-size: 16px;
  padding-left: 30px;
  width: 284px;
  overflow: hidden;
  text-overflow: clip;
}

.custom-branding span.label_colors span.required{
  margin-right: 4px;
  color: #f5222d;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.custom-branding .row-images{
  margin-top: 12px;
  margin-bottom: 12px;
}

.custom-branding #config_branding_favicon, 
.custom-branding #config_branding_logo, 
.custom-branding #config_branding_background{
  height: 104px;
}

.modal_custom_favicon .ant-modal-body{
  text-align: center !important;
}

.modal_custom_background .ant-modal-body > img, 
.modal_custom_logo .ant-modal-body > img{
  width: 100% !important;
}

.custom-system .sidebar-menu-sections li.ant-menu-item:hover{
  background-color: #e9e9e9 !important;
  color: rgba(0, 0, 0, 0.65) !important;
}

.custom-system .preview{
  height: 320px !important;
}

.custom-system .ant-form-item-label{
  line-height: unset !important;
}

.custom-system .row-title{
  margin-top: 0 !important;
}

.custom-system .row-images{
  margin-top: 4px;
  margin-bottom: 0px;
}

.custom-system .ant-form-item{
  margin-bottom: 12px !important;
}

.custom-system span.label_colors{
  padding-bottom: 1px;
}

.custom-system #config_branding_favicon, 
.custom-system #config_branding_logo, 
.custom-system #config_branding_background{
  height: 110px;
}

#config_branding_logo img.ant-upload-list-item-image{
  width: auto;
  height: 55px;
}

