.ui-datagrid-modal {
  padding-top: 4px;
}

.ui-datagrid-modal .app-icon {
  /* border: 1px solid #ddd; */
  border-left: 4px solid #ccc;
  border-radius: 6px;
  margin: 4px;
  background: #eee;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  cursor: pointer;
}

.ui-datagrid-modal .app-icon.apps {
  height: 70px;
  width: 112px;
}
.ui-datagrid-modal .app-icon.appsControl {
  height: 75px;
  width: 112px;
}

.ui-datagrid-modal .app-icon.browsers {
  height: 109px;
  width: 155px;
}

.ui-datagrid-modal .app-active {
  background: #cef !important;
}

.ui-datagrid-modal .app-icon:hover {
  border-left-color: #555;
  background: #ddd;
}

.ui-datagrid-modal .app-icon-symbol {
  display: block;
  font-size: 20px;
  margin-bottom: 4px;
  padding-top: 6px;
}

.ui-datagrid-modal .app-badge {
  position: absolute;
  top: -4px;
  right: -4px;
  font-size: 11px;
  font-family: monospace;
  color: #fff;
}

.ui-datagrid-modal .app-badge-total {
  color: #fff;
  background-color: #778992;
  padding: 2px 4px 2px 8px;
  border-radius: 9px 0px 0px 9px;
}

.ui-datagrid-modal .app-badge-value {
  color: #fff;
  background-color: #5c7380;
  padding: 2px 8px 2px 6px;
  border-left: 1px solid #366077;
  border-radius: 0px 9px 9px 0px;
}

.ui-datagrid-modal .tree-select {
  width: 90px;
  float: right;
}

.ui-datagrid-modal .ant-tree > li > span > .ant-tree-title {
  display: flex;
  width: 410px;
  justify-content: space-between;
}

.ui-datagrid-modal .ant-tree ul li .ant-tree-title {
  display: flex;
  width: calc(410px - 18px);
  justify-content: space-between;
}

.ui-datagrid-modal .geo-flag {
  padding-right: 8px;
}