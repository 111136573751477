#form-object-services.ant-form .ant-form-item,
#form-object-times.ant-form .ant-form-item,
#form-address.ant-form .ant-form-item{
  flex: 1 1 0%;
  margin-right: 8px;
}
#form-address.ant-form .check-unique .ant-form-item:first-child{
  flex: 5 1 0%;
  margin-right: 8px;
}
#form-address.ant-form .check-unique .ant-form-item:last-child{
  margin-right: 0px;
  margin-top: 30px
}
#form-object-services.ant-form .ant-form-item .ant-input-number,
#form-object-times.ant-form .ant-form-item .ant-time-picker{
  width: 100%
}

.ant-modal-footer span{
  margin: 0px 5px;
  display: inline-block;
}

